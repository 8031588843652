<template>

    <el-form ref="form" :model="form" label-width="100px">
      <input type="hidden" v-model="form.id" />
      <el-form-item label="标题">
        <el-input v-model="form.b_name"></el-input>
      </el-form-item>
      <el-form-item label="所属分类">
        <el-select v-model="form.cate_id"  placeholder="请选择">
          <el-option
              v-for="item in cateList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="alt">
        <el-input v-model="form.b_title"></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="form.top" :min="0" :max="100" label=""></el-input-number>
      </el-form-item>
      <el-form-item label="外部链接">
        <el-input v-model="form.b_url"></el-input>
      </el-form-item>
      <el-form-item label="图片链接">
        <el-upload
            class="upload-demo"
            action="/api/Upload/upload.html"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :file-list="form.fileList"
            list-type="picture"
            name="image[]"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过2Mb
          </div>
        </el-upload>
        <el-input v-model="form.b_img" type="hidden"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="form.status">
          <el-radio-button label="0">未审核</el-radio-button>
          <el-radio-button label="1">正常</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="handleClose">重置</el-button>
      </el-form-item>
    </el-form>

</template>

<script>
import axios from "axios";

export default {
  name: "getBannerView",
  data(){
    return{

      form: {
        id: "",
        cate_id: "",
        b_title: "",
        b_name: "",
        top: 0,
        status: 0,
        b_img: "",
        b_url: "",
        fileList:[],
        imgsrc:""
      },
      options: [{
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '正常'
      }],
      cateList:[],
    }
  },
  // created() {
  //   this.getCategory();
  // },
  mounted() {
    let geturl = window.location.href;
    let geturlinfo = geturl.split('?')[1];
    let getqys = new URLSearchParams('?'+geturlinfo);
    let id = getqys.get('id');
    axios.get('/api/Cate/index.html?type=banner').then((res)=>{
      if(res.data.code == 200){
        this.cateList = res.data.data;
      }

    });
    if(id > 0){
      axios.get('/api/Banner/getBanner.html?id='+id).then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }
      });
    }
  },
  methods:{
    getCategory() {    },
    resetData(){
      this.form = this.$options.data().form;
    },
    handleClose() {
      this.resetData()
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.b_img = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    onSubmit() {
      axios({
        method:'post',
        url:'/api/Banner/setBanner.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {

          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
          // this.$props.id = res.data.id;
          // this.form.id = res.data.id;
          this.$router.go(0);
        }
      })
    },
  }
}
</script>

<style scoped>

</style>