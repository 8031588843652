<template>
    <el-form ref="form" :model="form" label-width="100px">
      <input type="hidden" v-model="form.id" />
      <el-form-item label="标题">
        <el-input v-model="form.cate_name"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="form.type"  placeholder="请选择">
          <el-option
              v-for="item in cateList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="handleClose">重置</el-button>
      </el-form-item>
    </el-form>

</template>

<script>
import axios from "axios";

export default {
  name: "getLabelView",
  data(){
    return{

      form: {
        id: "",
        cate_name: "",
        type: "",
      },
      cateList:[
        {
          value: 'banner',
          label: 'banner'
        },
        {
          value: 'link',
          label: '友链'
        },
      ],
    }
  },
  // created() {
  //   this.getCategory();
  // },
  mounted() {
    let geturl = window.location.href;
    let geturlinfo = geturl.split('?')[1];
    let getqys = new URLSearchParams('?'+geturlinfo);
    let id = getqys.get('id');
    if(id > 0){
      axios.get('/api/Cate/getCate.html?id='+id).then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }
      });
    }
  },
  methods:{
    getCategory() {    },
    resetData(){
      this.form = this.$options.data().form;
    },
    handleClose() {
      this.resetData()
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.b_img = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    onSubmit() {
      axios({
        method:'post',
        url:'/api/Cate/setCate.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
          this.$router.go(0);
        }
      })
    },
  }
}
</script>

<style scoped>

</style>