<template>
<el-form ref="form" :model="form" label-width="100px">
    <el-form-item label="是否启用">
      <el-radio-group v-model="form.iswater">
        <el-radio-button label="1" >是</el-radio-button>
        <el-radio-button label="0">否</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="水印类型">
      <el-radio-group v-model="form.type">
        <el-radio-button label="img">图片</el-radio-button>
        <el-radio-button label="text">文字</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="水印位置">
      <el-radio-group v-model="form.water_site">
        <el-radio-button label="1">左上</el-radio-button>
        <el-radio-button label="2">顶中</el-radio-button>
        <el-radio-button label="3">右上</el-radio-button>
      </el-radio-group>
      <br>
      <el-radio-group v-model="form.water_site">
        <el-radio-button label="4">左中</el-radio-button>
        <el-radio-button label="5">中间</el-radio-button>
        <el-radio-button label="6">右中</el-radio-button>
      </el-radio-group>
      <br>
      <el-radio-group v-model="form.water_site">
        <el-radio-button label="7">左下</el-radio-button>
        <el-radio-button label="8">底中</el-radio-button>
        <el-radio-button label="9">右下</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="水印图片">
      <el-upload
          class="upload-demo"
          action="/api/Upload/upload.html"
          :on-preview="handlePreview2"
          :on-remove="handleRemove2"
          :on-success="handleAvatarSuccess2"
          :file-list="form.fileList2"
          list-type="picture"
          name="image[]"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
          只能上传jpg/png文件，且不超过2Mb
        </div>
      </el-upload>
      <el-input v-model="form.water_url" type="hidden"></el-input>
    </el-form-item>


    <el-form-item label="水印文字">
      <el-input v-model="form.water_text"></el-input>
    </el-form-item>
    <el-form-item label="水印字体">
      <el-upload
          class="upload-demo"
          action="/api/Upload/fileupload.html"
          :on-preview="handlePreview1"
          :on-remove="handleRemove1"
          :on-success="handleAvatarSuccess1"
          :file-list="form.fileList1"
          list-type="text"
          name="image[]"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
            文件不超过50Mb
        </div>
      </el-upload>
      <el-input v-model="form.water_font" type="hidden"></el-input>
    </el-form-item>
    <el-form-item label="水印字号">
      <el-input v-model="form.water_size" type="number"></el-input>
    </el-form-item>
    <el-form-item label="水印颜色">
      <el-input v-model="form.water_color"></el-input>
    </el-form-item>
    <el-form-item label="文字偏移量">
      <el-input v-model="form.water_offset" type="number"></el-input>
    </el-form-item>
    <el-form-item label="文字倾斜角度">
      <el-input v-model="form.water_angle" type="number"></el-input>
    </el-form-item>
    <el-form-item label="水印透明度">
      <el-input v-model="form.water_alpha" type="number"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">保存</el-button>
<!--      <el-button @click="go">取消</el-button>-->
    </el-form-item>
  </el-form>
</template>
<script>
import axios from "axios";

export default {
  name: "WaterView",
  data() {
    return {
      form: {
        iswater:0,
        type:'img',
        water_site:5,
        water_alpha:100,
        water_offset:0,
        water_angle:0,
        fileList1: [],
        fileList2: [],
      },
      fileList: [],
    };
  },
  created() {
    this.getBase();
  },
  methods: {
    getBase(){
      axios.get('/api/Water/index.html').then((res)=>{
          if(res.data.code == 200)  this.form = res.data.data;
      });
    },
    handleAvatarSuccess1(res) {
      let array = Object.values(res);
      this.form.water_font = array[0]['file_url'];
    },

    handleRemove1(file, fileList1) {
      console.log(file, fileList1);
    },
    handlePreview1(file) {
      console.log(file);
    },
    handleAvatarSuccess2(res) {
      let array = Object.values(res);
      this.form.water_url = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove2(file, fileList2) {
      console.log(file, fileList2);
    },
    handlePreview2(file) {
      console.log(file);
    },
    onSubmit() {
        this.axios({
          method:'post',
          url:'/api/Water/setWater.html',
          data:this.form
        }).then((res)=>{
          if(res.data.code != 200) {
            alert(res.data.msg);
          }
          else{
            alert(res.data.msg);
          }
        })
    },
  },
};
</script>
<style scoped lang='scss'>
.el-input-number {
  margin-left: 10px;
}
.el-radio-button{
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 18px;
  vertical-align: middle;
  display: inline-block;
}
</style>