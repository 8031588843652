<template>
  <div>
    <div> <p  class="category">友链管理</p><el-button size="mini" @click="goTo(0)" class="category add">新增</el-button></div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="ID"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px" >{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="友链标题"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px" v-html="scope.row.link_name"></span>
        </template>
      </el-table-column>
      <el-table-column
          label="外链图">
        <template slot-scope="scope">
          <span style="margin-left: 10px"><img :src="scope.row.imgsrc" style="max-width: 150px;max-height: 50px;"/></span>
        </template>
      </el-table-column>
      <el-table-column
          label="状态"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.status}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="goTo(scope.row.id)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="deleteLink(scope.$index,scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import axios from "axios";
// import Dialog from "@/views/pages/getContentView.vue"


export default {
  name: "LinkView",
  data() {
    return {
      tableData: [],
      id: '',
      total: 100,
    }
  },
  components:{
  },
  created() {
    this.getCate();
  },
  methods: {
    goTo(id){
      this.$router.push('/getLink?id='+id)
    },
    getCate(){
      axios.get('/api/Link/index.html').then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
        }
      });
    },
    deleteLink(index,row){
      let tdata = this.tableData;
      this.$confirm("是否确认删除","提示",{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
      }).then(function (){
        axios.delete('/api/Link/deleteLink.html',{data:{id:row.id}}).then((res)=>{
          tdata.splice(index, 1);
          alert(res.data.msg)
        });
      }).catch(function (){

      })

    }
  }
};
</script>
<style scoped lang=''>
</style>