import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './utils/axios';

Vue.config.productionTip = false
Vue.use(ElementUI);
router.beforeEach((to, from, next)=>{
  // let token = localStorage.getItem('token');
  let token = window.sessionStorage.getItem('token');
  let path = to.path;
  if(path == '/login'){
    next();
    return;
  }
  if(token){
    next();
  }else{
    next( {path:'/login',query:{ Rurl: to.fullPath} });
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
